.products {
  &-list {
    display: flex;
    flex-wrap: wrap;
    &-item {
      width: 300px;
      padding: 18px;
      img {
        cursor: pointer;
      }
      .picture {
        width: 264px;
        height: 352px;
        object-fit: cover;
      }
      &-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .texts {
          margin-top: 6px;
          font-size: 16px;
          .title {
            color: #999;
          }
          .price {
            margin-top: 6px;
          }
        }
        .like-icon {
          max-width: 20px;
        }
      }
    }
  }
}
