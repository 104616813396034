.view {
  padding: 24px;
  .wrapper {
    display: flex;
    justify-content: center;
  }
  &-info {
    margin-left: 8em;
    .title {
      font-size: 32px;
    }
    .price {
      margin-top: 12px;
      font-size: 22px;
    }
    .column {
      margin-top: 26px;
    }
    select {
      max-width: 120px;
      &:not(:first-child) {
        margin-top: 12px;
      }
    }
    button {
      margin-top: 24px;
      background-color: #717fe0;
      border-radius: 8px;
      width: 140px;
      height: 40px;
      padding: 6px;
      font-size: 16px;
      color: #fff;
      border: none;
    }
  }
  &-picture {
    max-width: 400px;
  }
}
