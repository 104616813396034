.contact {
  .wrapper {
    padding: 28px;
    display: flex;
    justify-content: center;
  }
  &-form {
    border: solid 1px #e6e6e6;
    padding: 84px;
    max-width: 600px;
    color: #333;
    h1 {
      text-transform: capitalize;
      text-align: center;
      font-size: 24px;
    }
    input {
      height: 50px;
      width: 100%;
    }
    textarea {
      width: 100%;
      height: 100px;
    }
    input,
    textarea {
      border: solid 1px #e6e6e6;
      margin-top: 24px;
      padding: 8px;
    }

    button {
      margin-top: 24px;
      width: 100%;
      height: 50px;
      border: none;
      background-color: #252525;
      color: #fff;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
  &-info {
    padding: 84px;
    border: solid 1px #e6e6e6;
    > div {
      display: flex;
    }
    div {
      padding: 12px;
      max-width: 260px;
    }
    h1 {
      font-size: 18px;
      color: #333;
    }
    p {
      margin-top: 12px;
      color: #888;
    }
    .purple {
      color: #717fe0;
    }
    .icon {
      margin-top: 12px;
      width: 24px;
      height: 24px;
    }
  }
}
