@import url("https://fonts.googleapis.com/css2?family=Festive&family=Itim&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Itim", cursive;
  font-weight: 400;
}

body {
  background-color: #fff;
  font-size: 14px;
  color: #252525;
}

// Default Styles
input,
textarea,
button {
  outline: none;
}

a {
  text-decoration: none;
  color: #252525;
}

li {
  list-style-type: none;
}

img {
  width: 100%;
}

li,
button {
  cursor: pointer;
}

.wrapper {
  max-width: 1200px;
  margin: auto;
}

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.auth-link {
  color: #778bef;
  font-weight: 700;
  letter-spacing: 0.6px;
}

.icon {
  width: 16px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.logo {
  max-width: 120px;
  margin: 62px;
}


.table {
  margin-top: 24px;
  padding: 26px;
  min-width: 400px;
  border: solid 1px #e6e6e6;
  color: #555;
  th {
    text-transform: uppercase;
    font-size: 16px;
  }

  tr {
    border-bottom: solid 1px #e6e6e6;
  }
  img {
    max-width: 60px;
    margin-top: 16px;
  }
  .counter {
    display: flex;
    margin-bottom: 16px;
  }
  .decrease,
  .increase,
  .result {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    width: 46px;
    height: 46px;
    border: solid 1px #e6e6e6;
  }

  .result {
    background-color: #e6e6e6;
    color: #666;
  }
  .decrease {
    border-radius: 6px 0 0 6px;
  }
  .increase {
    border-radius: 0 6px 6px 0;
  }
  .price,
  .total {
    font-size: 16px;
  }

  .title {
    font-size: 16px;
  }
  .delete-icon {
    cursor: pointer;
    max-width: 16px;
    margin-bottom: 12px;
  }
}