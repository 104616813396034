.wish-list {
  .product {
    padding: 24px;
    max-width: 320px;
    .title {
      margin-top: 8px;
    }
    .price {
      margin-top: 4px;
    }
    button {
      margin-top: 12px;
      width: 100%;
      height: 40px;
      border: none;
      border-radius: 6px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }
  }
}
