.form {
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  padding: 48px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-weight: 700;
    font-size: 29px;
    color: #2e1244;
  }

  h4 {
    margin-top: 16px;
    font-size: 14px;
  }

  &-continue-with {
    margin-top: 28px;
    width: 100%;

    div {
      cursor: pointer;
      background-color: #e1e4e9;
      height: 60px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin-left: 8px;
        color: #1e203d;
        font-weight: 700;
      }

      &:last-child {
        margin-top: 16px;
      }
    }
  }

  &-line {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 2px;

    div {
      margin-top: 4px;
      background-color: #bfc2cd;
      height: 1px;
      width: 100%;
    }

    p {
      margin: 0 8px;
    }
  }

  &-fields {
    margin-top: 22px;
    width: 100%;
    input {
      letter-spacing: 0.5px;
      width: 100%;
      height: 58px;
      border: 1px solid #d9d9d9;
      border-radius: 12px;
      padding: 8px;

      &:last-child {
        margin-top: 22px;
      }
    }
  }

  // Only for login section
  .remember-forgot {
    margin-top: 22px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .remember-me {
      cursor: pointer;
      display: flex;
      .checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        border: 0.7px solid #d9d9d9;
        border-radius: 4px;
      }
      p {
        margin-left: 6px;
        color: #301445;
      }
    }
  }

  button {
    font-size: 15px;
    margin-top: 22px;
    background: #778bef;
    border-radius: 12px;
    height: 58px;
    width: 100%;
    border: none;
    cursor: pointer;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.8px;
  }

  .redirect {
    margin-top: 12px;
    display: flex;
    padding: 4px;

    a {
      margin-left: 8px;
    }
  }
}
