.header {
  padding: 24px;
  margin-bottom: 42px;
  .wrapper {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-logo {
    cursor: pointer;
  }
  &-links {
    margin-left: 44px;
    display: flex;
    align-items: center;

    a {
      font-size: 16px;
      &:not(:first-child) {
        margin-left: 24px;
      }
      &:hover {
        color: #6c7ae0;
      }
    }
  }
  &-tools {
    display: flex;
    align-items: center;
    .wish-list {
      margin-left: 12px;
    }
    button {
      width: 80px;
      height: 40px;
      border-radius: 6px;
      outline: none;
      border: none;
      margin-left: 18px;
    }
    .sign-in {
      background-color: #e6e6e6;
      border: solid 1px #ccc;
      color: #000;
    }
    .sign-up {
      color: #fff;
      background-color: #353535;
    }
  }
  .basket {
    cursor: pointer;
  }
}
