.basket {
  .wrapper {
    display: flex;
    justify-content: space-between;
  }

  .column {
    > div {
      padding: 28px;
    }
  }
 
  .checkout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 200px;
    border: solid 1px #e6e6e6;
    padding: 26px;
    h1 {
      text-transform: uppercase;
    }
    h3 {
      margin-top: 12px;
      b {
        color: #333;
      }
    }
    button {
      margin-top: 18px;
      width: 100%;
      height: 42px;
      font-size: 16px;
      border-radius: 12px;
      background-color: #222;
      color: #fff;
      text-transform: uppercase;
      border: none;
    }
  }
}
